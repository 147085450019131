import { endpoints,labels,externalApplicationUrls } from './constants';

export const environment = {
  production: true,
  type: 'qa',
  b2cSettings: {
    authUrl: "https://b2cqathefa.b2clogin.com",
    tenant: "b2cqathefa.onmicrosoft.com",
    clientID: "f3780614-415e-403f-83d2-f28fc1437f2f",
    knownAuthorities: "b2cqathefa.b2clogin.com",
    signInPolicy: "B2C_1A_SIGNUP_SIGNINACTSOC",
    signUpPolicy: "B2C_1A_signup_signinACT",
    passwordResetPolicy: "B2C_1A_pwresetCustomOTPPFF_STR",
    updatePasswordPolicy: "B2C_1A_SelfpwresetPFF_STR",
    updatePasswordPolicyForFancode: "B2C_1A_SelfpwresetPFF_STR_FAN",
    updateSignInEmailPolicy: "B2C_1A_UpdateSignInEmailPFF_STR_EMAILUPD",
    userAddressPolicy: "b2c_1a_profileaddresseditinmasterpff_strjs",
    updateMobilePolicy:
      "B2C_1A_profilemobileTtelephoneeditinmasterPFF_STR_MBLUPD",
    signUpPolicyFA: "B2C_1A_FAsignupCustomOTPPFF_STRUIVERDyn",    
    forgottenEmailPolicy:
       "B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAILDYN", 
    dontKnowMyFanPolicy: "B2C_1A_FARecoveryCustomOTPPFF_STRUIVERDyn",
    authenticateUserForSettingsPolicy : "B2C_1A_SETTINGSPFF_STR",
    redirectUri: "https://zqapffwebapp11.z6.web.core.windows.net/",
    // redirectUri: "http://localhost:4020",
    b2cScopes: [
      "https://b2cqathefa.onmicrosoft.com/ACTScopeApp/user_impersonation",
      "https://b2cqathefa.onmicrosoft.com/ACTScopeApp/READ",
      "https://b2cqathefa.onmicrosoft.com/ACTScopeApp/WRITE",
    ],
    b2cParameter: "AM",
    extraQueryParameters: {
      "FAAppToken": "AM"
    }
  },
  subscriptionKey: "Ocp-Apim-Subscription-Key",
  subscriptionValue: "56b6cf17db8347b5a4cd999d4333840a",
  consentSubscriptionValue: "995b869aa90d4b349e4b28ed354337f8",
  countyListSubscriptionValue: "22f6096158cf46118655d5596a8422bc",
  listOfDisabilitiesSubscriptionValue: "9a3f41b78c0a4d4faabc7224005e2f9b",
  paymentSubscriptionValue: "cf09f227c5b347be861b7c53bcc1f0bb",
  saveDisabilitiesSubscriptionValue: "9a3f41b78c0a4d4faabc7224005e2f9b",
  otherSubscriptions: [   
    {
      serviceUrl:'paas-api-authz/',
      subscriptionValue: 'cf09f227c5b347be861b7c53bcc1f0bb'
    },
    {
      serviceUrl: 'referencedata-subky/',
      subscriptionValue: '9a3f41b78c0a4d4faabc7224005e2f9b'
    },
    {
      serviceUrl: 'referencedata-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'playerreg-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'b2cuseraccount-api-authz/',
      subscriptionValue: ''
    },
    {
      serviceUrl: 'dbs-authz/',
      subscriptionValue: ''
    }
  ],
  apiEndPoints: {
    headerApiUrl:
    "https://zqapffapimngt1.azure-api.net/qa/api/Navigation/TopLinksAsHtml?TopLinksAsHtml?Method=TopLinksAsHtml&showSignIn=false&userAuthenticated=false&userName= &urlSignIn= &urlSignOut= &UrlMyAccount= &urlIsUserSignedIn= &hideFooterSponsors=false&hideFooterEmailForm=false&hideMerchandiseLink=false&useMinimalCss=false",
    footerApiUrl:
    "https://zqapffapimngt1.azure-api.net/qa/api/Navigation/HeaderAndFooterAsHtml?Method=HeaderAndFooterAsHtml&showSignIn=false&userAuthenticated=false&userName= &urlSignIn= &urlSignOut= &UrlMyAccount= &urlIsUserSignedIn= &hideFooterSponsors=false&hideFooterEmailForm=false&hideMerchandiseLink=false&useMinimalCss=false",
    serverUrl : "https://zqapffapimngt1.azure-api.net/",
    paymentServerUrl: "https://zqapayapimngt1.azure-api.net/",
    refregServerUrl: "https://zqapffwebapp1.z6.web.core.windows.net/",
    playerRegServerUrl: "https://zqapffwebapp23.z6.web.core.windows.net/",
    wholeGameSystem: "https://wholegame.thefa.test/Account/RedirectFromPFF",
    refreeRegistration: "https://zqapffwebapp1.z6.web.core.windows.net/#/home",
    leadPlayMakerLink: "http://cloud-stg.thefa.com/learning",
    dispensationRequest:"https://dispensationqa.thefa.com/Dispensation-Request---New/",
    applicationDashboard:"https://dispensationqa.thefa.com/parent-carer-dashboard/",  
    dbsService: "https://zqapffapimngt1.azure-api.net/",
    services :{
      ipsAuthzService: "ips-api-authz/",
      ipsService : "accountmanagement.qa.com/",
      mediaService : "media/thefaqa.com/",
      notificationService: "notificationv1/qa.com/",
      CRSservice: "FACoreServiceAPI.qa.com/",
      refereeService: "refereev1.qa.com/",
      playerReg:"playerreg-authz/",
      referenceDataSubky: "referencedata-subky/",
      referenceDataAuthz: "referencedata-authz/",
      passApi:"paas-api-authz/",
      b2cuseraccount:"b2cuseraccount-api-authz/",
      dbsService:"dbs-authz/"
    },
    endpoints : endpoints,
    labels:labels,
    externalApplicationUrls: externalApplicationUrls   
  },
  edi: {
    apiEndPoints: {
      services:{
        ediAuthz: "edni-api-authz/",
        ediSubky: "edni-api-subky/"       
      },
      getEDIDetailsApi:
        "https://zqapffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/GetEDnIDetails",
      addEDIDetailsApi:
        "https://zqapffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/AddEDnIDetails",
      deleteEDIDetailsApi:
        "https://zqapffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/DeleteEDnIDetails",
      getEDIQuestionnaireApi:
        // "https://zqapffapimngt1.azure-api.net/edni-api-authz/v1/api/EDnI/GetEDnIQuestionnaire",
        "https://zqapffapimngt1.azure-api.net/edni-api-subky/v1/api/EDnI/GetEDnIQuestionnaire"
    },
    labels: {
      diversityAndInclusion: {
        ediHeading: "Diversity and Inclusion",
      },
      ediQuestionnaireMapping: [
        {
          id: 1,
          category: "genderAtBirth",
        },
        {
          id: 2,
          category: "gender",
        },
        {
          id: 3,
          category: "sexuality",
        },
        {
          id: 4,
          category: "schoolType",
        },
        {
          id: 5,
          category: "parentOccupation",
        },
        {
          id: 6,
          category: "disability",
        },
        {
          id: 7,
          category: "disabilityProblems",
        },
        {
          id: 8,
          category: "pathwayEligibility",
        },
        {
          id: 9,
          category: "ethnicity",
        },
        {
          id: 10,
          category: "religion",
        },
      ],
    },
    externalApplicationUrls: {
      referee: "https://zqapffwebapp1.z6.web.core.windows.net/#/success",
      player: "https://zqapffwebapp23.z6.web.core.windows.net/#/redirection?app=edi",
      Discipline: "",
      education: "",
      consent: "https://zqapffwebapp23.z6.web.core.windows.net/#/redirection?app=edi",
      wgs: "http://wgsportal.thefa.com/",
    }
  }
  
};